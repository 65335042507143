import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

export const Wrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-wrap: nowrap;
  background: ${getColor('surfaceBlack')}; 
  color: ${getColor('neutralWhite')};
  padding: 4px 16px 24px;
  font-family: ${font.graphikCond};
  margin-bottom: 24px;
  margin-top: 48px;

  .heading {
    margin-top: 20px;
  }

  @media (${breakpoint.mdMin}) {
    padding: 20px 24px 24px;
    margin: 0 -24px 48px;
  }

  @media (${breakpoint.lgMin}) {
    padding: 20px 48px 48px;
    align-items: center;
  
    .heading {
      margin-top: 0px;
    }
  }

  @media (${breakpoint.xlMin}) {
    margin: 48px -48px;
  }


`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;

  @media (${breakpoint.lgMin}) {
    margin-right: 48px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding: 0;
`;

export const Item = styled.li<{ theme: Theme }>`
  border-bottom: 1px solid ${getColor('borderTertiary')};
  flex-basis: 100%;
  margin-bottom: 24px;
  margin-left: 0;
  margin-right: 4%;
  max-width: 100%;
  padding-bottom: 24px;

  &:nth-last-of-type(-n + 1) {
    border-bottom: none;
  }

  @media (${breakpoint.mdMin}) {
    flex-basis: 45%;
    max-width: 45%;

    &:nth-last-of-type(-n + 2) {
      border-bottom: none;
    }
  }

  @media (${breakpoint.lgMin}) {
    flex-basis: 28%;
    max-width: 28%;

    &:nth-last-of-type(-n + 3) {
      border-bottom: none;
    }
  }

  .tag {
    color: ${getColor('textWhite')};
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .description {
    display: block;
    font-weight: 300;
    text-align: left;
    letter-spacing: 1px;
    font-size: 20px;
    line-height: 24px;
    color: ${getColor('textWhite')};
    
    @media (${breakpoint.smMin}) {
      letter-spacing: initial;
      line-height: initial;
    }
  }
  
  .authors {
    margin-top: 8px;
  }
  
  .authors span, .authors span a {
    ${getBodyStyle('graphikCompact', { default: 'small' })};
    color: ${getColor('textWhite')};
  }
  
  .authors span a {
    font-weight: 500;
  }
`;
