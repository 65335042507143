import { ArticlePreview } from 'interfaces/content/articles/Articles';
import Row from 'components/Grid/Row';
import * as S from 'styles/rightRail';
import { setEventAction } from 'utils/trackDataUtils';
import { Wrapper as SBWrapper } from 'components/SidebarContentPreview/SidebarContentPreviewStyles';
import ArticleCard from 'components/Globals/ArticleCard';
import FeaturedArticle from 'components/Home/FeaturedArticle';
import HomeLatestNews, { HomeLatestNewsProps } from 'components/Home/LatestNews';
import { Wrapper, BottomWrapper, ArticlesWrapper, GridWrapper } from './FeaturedPrimaryStyles';

export interface FeaturedPrimaryProps {
  featuredArticle: ArticlePreview;
  articles: ArticlePreview[];
  latest: HomeLatestNewsProps | null;
  isFirstPrimary?: boolean;
  heading?: string;
  headingLink?: string;
}

const FeaturedPrimary = ({ featuredArticle, articles, latest, isFirstPrimary }: FeaturedPrimaryProps) => (
  <Wrapper>
    <FeaturedArticle
      {...featuredArticle}
      isFirstPrimary={isFirstPrimary}
    />
    {articles.length >= 1 && articles.length <= 4 && (
      <BottomWrapper>
        <ArticlesWrapper>
          <GridWrapper>
            <Row>
              {articles.map((article) => (
                <li key={article.title}>
                  <ArticleCard
                    article={article}
                    variant='hero'
                    eyebrowType='red'
                    titleSize={{ default: 'level3' }}
                    trackingData={{
                      contentPlacementCD: 'hero',
                      eventAction: setEventAction(article.eyebrowSectionLabel, article.eyebrowTagLabel),
                      eventCategory: 'article',
                      eventLabel: article.title,
                    }}
                  />
                </li>
              ))}
            </Row>
          </GridWrapper>
        </ArticlesWrapper>
        {latest && (
          <S.RightRail>
            <SBWrapper $isMobileVisible>
              <HomeLatestNews {...latest} />
            </SBWrapper>
          </S.RightRail>
        )}
      </BottomWrapper>
    )}
  </Wrapper>
);

export default FeaturedPrimary;
