import React from 'react';
import AdSlot from './AdSlot';
import { InStreamAd, AdsCenteredDiv } from './Styles';

interface HomePageInStreamProps {
  id: number;
}

const HomePageInStream: React.FC<HomePageInStreamProps> = ({ id }: HomePageInStreamProps) => (
  <AdsCenteredDiv>
    <InStreamAd className='homepage'>
      <AdSlot
        placementName='Homepage-InStream'
        index={id + 1}
        slotId={`InStream${id}`}
      />
    </InStreamAd>
  </AdsCenteredDiv>
);

export default HomePageInStream;
