import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div`
  width: 100%;

  @media (${breakpoint.lgMin}) {
    max-width: 300px;
  }
`;

export const Title = styled(Heading)`
  border-bottom: ${getColor('borderTertiary')} 1px solid;
  padding-bottom: 8px;
  text-align: left;

  @media (${breakpoint.lgMin}) {
    padding-top: 8px;
  }
`;

export const List = styled.ul`
  @media (${breakpoint.mdMin}) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (${breakpoint.lgMin}) {
    display: block;
  }

  li {
    display: flex;
    flex: 50%;
    min-width: 164px;
    
    @media (${breakpoint.mdMin}) {
      max-width: 50%;
    }

    @media (${breakpoint.lgMin}) {
      max-width: 100%;
    }
  }
`;
