import styled from 'styled-components';
import { brandColors } from 'styles/colors';
import { breakpoint, grid, GridSpacing } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const Wrapper = styled.div`
display: block;

@media (${breakpoint.mdMin}) {
  margin-top: 24px;
}

@media (${breakpoint.lgMin}) {
  margin-top: 0;
}

@media (${breakpoint.lgMin}) {
  lost-center: 1260px;
}`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (${breakpoint.mdMin}) {
    padding: 0 24px;
  }

  @media (${breakpoint.lgMin}) {
    padding: 0 24px;
    flex-direction: row;
    padding: 0 0;
  }

  @media (${breakpoint.xlMin}) {
    margin-right: 24px;
    padding: 16px 0 0;
  }
`;

export const ArticlesWrapper = styled.div`
  max-width: none;
  padding: 0 16px;
  order: 1;

  @media (${breakpoint.mdMin}) {
    padding: 0;
  }

  @media (${breakpoint.lgMin}) {
    order: 0;
  }

  @media (${breakpoint.lgMin}) {
    width: ${grid(9, 8.25, GridSpacing.lg)};
  }

  @media (${breakpoint.xlMin}) {
    margin-right: 0;
    width: Min(${grid(9, 8.25, GridSpacing.xl)}, 1007px);
    max-width: 1007px;
  }
`;

export const GridWrapper = styled.div`
  margin-top: 24px;
  margin-right: 0;
  margin-bottom: 16px;
  max-width: none;

  @media (${breakpoint.mdMin}) {
    margin-top: 0;
    margin-bottom: 48px;
  }

  @media (${breakpoint.lgMin}) {
    margin-left: 24px;
    padding-left: 24px;
  }

  @media (${breakpoint.xlMin}) {
    margin-bottom: 48px;
  }
`;

export const SidebarWrapper = styled.div<{ theme: Theme }>`
  background: ${getColor('surfaceWhite')};
  height: fit-content;
  margin-top: 24px;
  max-width: none;
  order: 0;
  position: relative;
  z-index: 2;

  @media (${breakpoint.mdMin}) {
    margin: 0 0 48px;
  }

  @media (${breakpoint.lgMin}) {
    border-left: ${brandColors.own.surfaceBrand} 5px solid;
    bottom: 60px;
    flex: 1;
    flex-direction: column;
    left: -10px;
    margin: 60px 0 0;
    order: 2;
    position: relative;
  }

  @media (${breakpoint.xlMin}) {
    left: -20px;
    max-width: 301px;
  }
`;
