import type { Article } from 'interfaces/content/articles/Articles';
import { SubscriberContext } from 'services/Subscriber';
import { EventData } from 'services/Gtm/entities';
import { trackEvent } from 'services/Gtm/functions';
import { PageType } from 'interfaces/content/articles/Post';

export const getArticleTrackingData = (article: Article, user: SubscriberContext, subdomain: string) => {
  const hasFeaturedMediaTypeVideo =
    article.featuredMediaType === 'fortune_video' || article.featuredMediaType === 'stn_video_media';

  const trackingData: EventData = {
    HasVideoCD: hasFeaturedMediaTypeVideo,
    articleNameCD: article.title,
    authorNameCD: article.authorNames,
    cmsCategoryCD: 'article',
    cmsSubCategoryCD: article.primarySection.name,
    contentCMSID: article.postId,
    contentPlacementCD: `${subdomain} Widget`,
    contentPublishedDateCD: article.dateGmt,
    contentTagsCD: article.tagNames,
    hitIDCD: '',
    loginStatusCD: user.isLoggedIn ? 'logged-in' : 'guest',
    platformCD: article.platformCD || 'own',
    premiumCategoryCD: article?.premiumCategory.name || '',
    sessionIDCD: '',
    userAgentCD: typeof window !== 'undefined' ? window.navigator?.userAgent : '',
  };

  return { trackingData };
};

export const trackInlineButtonClick = (event: Event, defaultTrackingData: EventData, subdomain: string) => {
  const trackingData = {
    ...defaultTrackingData,
    contentPlacementCD: `Inline ${subdomain} Button`,
    eventAction: 'inline click',
    eventCategory: `Inline ${subdomain} Button`,
    eventLabel: (event.target as HTMLAnchorElement).textContent || '',
  };

  trackEvent(trackingData);
};

export const trackArticleLinkClick = (event: Event, defaultTrackingData: EventData) => {
  const target: HTMLAnchorElement = event.target as HTMLAnchorElement;
  // the anchor element that was clicked could be a wrapper of another element (e.g. image)
  // in this case the target might not be the anchor itself
  const articleUrl = target.tagName === 'A' ? target.href : target?.closest('a')?.href;
  if (!articleUrl) return;
  let urlDomain;
  try {
    urlDomain = new URL(articleUrl).origin;
  } catch (err) {
    return;
  }
  const isExternalURL = urlDomain !== window.location.origin;
  if (!isExternalURL) return;

  const trackingData = {
    ...defaultTrackingData,
    contentPlacementCD: 'Article Body',
    eventAction: urlDomain,
    eventCategory: 'Outbound Link Click',
    eventLabel: articleUrl,
  };

  trackEvent(trackingData);
};

export const setEventAction = (eyebrowSectionLabel: string, eyebrowTagLabel: string): string => {
  let eventActionContent;

  if (
    eyebrowSectionLabel !== 'No Primary Section Found' &&
    eyebrowTagLabel !== 'No Primary Tag Found' &&
    eyebrowTagLabel !== ''
  ) {
    eventActionContent = `${eyebrowSectionLabel} : ${eyebrowTagLabel}`;
  } else if (eyebrowSectionLabel !== 'No Primary Section Found') {
    eventActionContent = `${eyebrowSectionLabel}`;
  } else {
    eventActionContent = 'Not Set';
  }

  return eventActionContent;
};

export const setPrefixedDbID = (ID: string | number, pageType?: PageType): string => {
  switch (pageType) {
    case 'edu-category':
      return `C${ID}`;
    case 'edu-rankings-list':
      return `R${ID}`;
    case 'edu-school':
      return `S${ID}`;
    default:
      return `${ID}`;
  }
};