import type { GetStaticProps, NextPage } from 'next';
import { setPianoConfig } from 'services/Piano';
import { HomePage, HomepageItem } from 'pages/Home/HomePage';
import { setHeadData } from 'utils/metaDataUtils/headData';
import setPageGtmConfig from 'services/Gtm/setPageGtmConfig';
import setHomepageAdConfig from 'services/Ad/config/setHomepageAdConfig';
import setIterablePageData from 'services/Iterable/setIterablePageData';
import { HeaderNav } from 'interfaces/navigation/Header';
import { setPermutivePageConfig } from 'services/Permutive';
import { FooterProps } from 'components/Footer';
import getLatest from 'api/Latest/getLatest';
import getFooter from 'api/Footer/getFooter';
import { handleError } from 'api/errorHandling';
import { getHomepage } from 'api/Home/getHomepage';
import getMenu from 'api/Header/getMenu';

interface HomepageProps {
  content: HomepageItem[];
}

const HomepageNextPage: NextPage<HomepageProps> = ({ content }: HomepageProps) => <HomePage items={content} />;

export default HomepageNextPage;

export const getStaticProps: GetStaticProps = async () => {
  try {
    const homepage = await getHomepage();
    const latest = await getLatest(4, 0);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, no-prototype-builtins
    if (homepage.content[0].props.hasOwnProperty('latest')) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      homepage.content[0].props.latest.latestNews = latest?.posts.slice(0, 4);
    }

    const menu: HeaderNav = await getMenu('LEFT_MENU_COM', 'RIGHT_MENU_COM', 'TOPIC_NAVIGATION_COM', 'own', null);

    const footer: FooterProps = await getFooter();

    const dataLayer = setPageGtmConfig(homepage);
    const headData = setHeadData(homepage);
    const iterablePageViewData = setIterablePageData(homepage);
    const pageAdConfig = setHomepageAdConfig(homepage);
    const permutivePageConfig = setPermutivePageConfig(homepage);
    const pianoPageConfig = setPianoConfig(homepage);

    return {
      props: {
        content: homepage.content,
        dataLayer,
        footer,
        headData,
        headerNav: menu,
        iterablePageViewData,
        pageAdConfig,
        permutivePageConfig,
        pianoPageConfig,
      },
      revalidate: 60,
    };
  } catch (e) {
    return handleError(e as Error);
  }
};
